@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.multiselect-dropdown>div>span>span {
	max-width: 500px !important;
}

@import '~@ng-select/ng-select/themes/default.theme.css';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-balham.css';
// @import 'ag-grid-community/styles/ag-theme-alpine.css';

html,
body {
	height: 100%;
	width: 100%;
}

html,
body {
	font-family: Roboto, sans-serif;
	//font-size: 48px;
}

body.has-nav-bar {
	padding-top: 56px !important;
}

body.has-nav-bar:not(.ignore-body-padding) {
	padding-left: 25px !important;
	padding-right: 25px !important;
	padding-top: 80px !important;
}

a {
	color: #336fbb !important;
}

.modelPadding {
	padding: 20px 20px 20px 20px;
}

.mat-tab-label .mat-tab-label-content {
	font-size: large !important;
}

.mat-mdc-tab-body-wrapper {
	height: 100% !important;
}

.mat-expansion-panel-body {
	padding: 12px !important;
	font-size: 14px;
}

.mat-mdc-tab-body-content {
	overflow: auto !important;
}

mat-progress-bar {
	border-radius: 20px !important;
	height: 20px !important;
}

.mdc-linear-progress__bar-inner {
	border-top-width: 20px !important;
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -75px 0 0 -75px;
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3498db;
	//border-bottom: 16px solid #3498db;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.tot_payment_master_detail {
	font-weight: bold !important;
	background-color: rgb(252, 252, 173) !important;
}

.ptp_payment_master_detail {
	background-color: rgba(210, 207, 207, 0.282) !important;
}

.ag-header-cell-text {
	font-weight: 600 !important;
}

.form-check-input:checked {
	background-color: #0d6efd !important;
	border-color: #0d6efd !important;
}

.form-check-input:checked[type=checkbox] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !important;
}

.ag-cell-value,
.ag-group-value {
	font-size: 15px !important;
}

.mdc-form-field>label {
	font-size: 15px !important;
}

// #mat-tab-content-0-7>div {
// 	height: 850px !important;
// }

.mat-expansion-panel-header {
	padding: 10px !important;
}

.multiselect-dropdown>div>span>span>a {
	color: white !important;
}

.ag-paging-panel>span {
	color: black !important;
}

.collection-wrapper {
	.ag-root-wrapper-body {
		margin-top: 45px;
	}
}

.collection-wrapper {
	.ag-paging-panel {
		position: absolute !important;
		top: 0 !important;
		right: 0 !important;
		border-top: none !important;
	}
}

.ptp-wrapper {
	height: 100%;
}

#mat-mdc-dialog-0>div>div>app-login-inquiry-modal>div>div.mat-mdc-dialog-content.mdc-dialog__content {
	padding: 44px 44px 0 44px !important;
}

#mat-mdc-dialog-0>div>div>app-login-inquiry-modal>div>div.mat-mdc-dialog-actions.mdc-dialog__actions {
	padding-left: 23px !important;
}